<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('category-modal', {id: 0,type:''}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="ml-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create_cat') }}
                    </b-button>

                    <b-button
                        @click="$root.$children[0].openModal('category-modal', {id: 0,type:'sub'}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="ml-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create_sub_cat') }}
                    </b-button>

                    <b-button
                        v-if="categories.length == 1"
                        @click="$root.$children[0].openModal('category-modal', {id: categories[0]}, afterSave, {width: '800px'})"
                        variant="info"
                        class="ml-2"
                    >
                        <font-awesome-icon icon="edit"/>
                        {{ $t('common.button.edit') }}
                    </b-button>
                    <b-button
                        v-if="categories.length > 0"
                        @click="$removeConfirm('Category/deleteCategory', categories, shown)"
                        class="ml-2"
                        variant="danger"
                        :title="$t('common.title.delete')"
                    >
                        <font-awesome-icon icon="trash"/>
                        {{ $t('common.button.delete') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="relative_tree">
                    <treeselect
                        :multiple="true"
                        :options="getCategoryOptionsTree()"
                        v-model="categories"
                        :matchKeys="matchKeys"
                        :alwaysOpen="true"
                        valueConsistsOf="ALL"
                        :flat="true"
                        :allowSelectingDisabledDescendants="true"
                        :autoSelectDescendants="false"
                        :autoDeselectDescendants="false"
                    />
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'Settings-category',
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            dragging: false,
            categories: [],
            matchKeys: ['name'],
        }
    },
    methods: {
        ...mapGetters('Category', ['getCategoryOptionsTree']),
        shown() {
            const fetchOptionsTree = this.$store.dispatch('Category/fetchOptionsTree')

            Promise.all([fetchOptionsTree]).then(() => {
            }).finally(() => {
                this.loading = false
            })
        },
        afterSave() {
            this.$store.dispatch('CommonData/fetchCommonData')
            this.shown()
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>